class Utils {

    static priceFormatter(_price) {
        let price = Number.parseInt(Math.floor(Number.parseFloat(_price)).toString())
        let decimal = 0
        if (parseInt(price.toString()) < price) {
            decimal = parseInt(price.toString().split('.')[1])
            price = parseInt(price.toString())
        }
        const parts = price.toString().split('').reverse();
        let str = ""
        for (let i = 0; i < parts.length; i += 3) {
            if (parts[i]) {
                str += parts[i]
                if (parts[i + 1]) {
                    str += parts[i + 1]
                    if (parts[i + 2])
                        str += parts[i + 2] + "."
                    else break
                } else
                    break
            } else
                break

        }
        str = str.split('').reverse().join('')
        if (str.charAt(0) === '.') {
            let _str = str.split('')
            _str.shift()
            str = _str.join('')

        }
        return decimal > 0 ? str + ',' + decimal : str
    }

    static jwtTokenKey = () => localStorage.getItem("REFRESH")
    static token = () => localStorage.getItem('TOKEN')
    static storeToken = (token) => localStorage.setItem('TOKEN', token)
    static storeEmployee = (employee) => localStorage.setItem('USER', JSON.stringify(employee))
    static roles = () => [
        {
            name: "AGENT",
            value: "Agent"
        },
        {
            name: "WHOLESALER",
            value: "Grossiste"
        },
        {
            name: "ACCOUNTANT",
            value: "Comptable"
        }, {
            name: "MANAGER",
            value: "Manager"
        }, {
            name: "ADMIN",
            value: "Administrateur"
        }
    ]
    static apiUrl = () => 'https://api.possa.biz/v1/admin'
    static socketUrl = () => 'https://socket.possa.biz'
    //static apiUrl = () => 'http://localhost:3001/v1/admin'
}

module.exports = {
    Utils
}
