<template>
  <base-nav class="navbar-top navbar-dark"
            id="navbar-main"
            :show-toggle-button="false"
            expand>
    <form @submit="search" class="navbar-search navbar-search-dark form-inline mr-3 d-none d-md-flex ml-lg-auto">
      <div class="form-group mb-0">
        <base-input v-model="searchQuery" placeholder="Search"
                    class="input-group-alternative"
                    alternative="" @keypress="typeCode"
                    addon-right-icon="fas fa-search">
        </base-input>
      </div>
    </form>
    <ul class="navbar-nav align-items-center d-none d-md-flex">
      <li class="nav-item dropdown">
        <base-dropdown class="nav-link pr-0">
          <div class="media align-items-center" slot="title">
                <span class="avatar avatar-sm rounded-circle cursor-pointer">
                  <img alt="Image placeholder" :src="user.account.user.avatar">
                </span>
            <div class="media-body ml-2 d-none d-lg-block">
              <span class="mb-0 text-sm  font-weight-bold cursor-pointer text-uppercase">{{
                  user.account.user.name
                }}</span>
            </div>
          </div>

          <template>
            <div class=" dropdown-header noti-title">
              <h6 class="text-overflow m-0">Bienvenue !</h6>
            </div>
            <router-link to="/profile" class="dropdown-item">
              <i class="ni ni-single-02"></i>
              <span>Mon profil</span>
            </router-link>

            <router-link to="/" class="dropdown-item">
              <i class="ni ni-chart-bar-32"></i>
              <span>Accueil</span>
            </router-link>
            <router-link to="/sponsorship" class="dropdown-item">
              <i class="ni ni-delivery-fast"></i>
              <span>Mes parrainages</span>
            </router-link>
            <div class="dropdown-divider"></div>
            <button @click="logout" class="dropdown-item">
              <i class="ni ni-user-run"></i>
              <span>Déconnexion</span>
            </button>
          </template>
        </base-dropdown>
      </li>
    </ul>
  </base-nav>
</template>
<script>
export default {
  data: () => ({
    activeNotifications: false,
    showMenu: false,
    searchQuery: '',
    user: null
  }),
  methods: {
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    hideSidebar() {
      this.$sidebar.displaySidebar(false);
    },
    toggleMenu() {
      this.showMenu = !this.showMenu;
    },
    typeCode() {

    },
    search() {
      alert(this.searchQuery)
      return true
    },
    logout() {
      localStorage.clear()
      this.$router.push('/login')
    }
  },
  mounted() {
    this.user = JSON.parse(localStorage.getItem('USER'))
  }
};
</script>

<style scoped>
.cursor-pointer {
  cursor: pointer;
}
</style>
