import Vue from 'vue'
import Router from 'vue-router'
import DashboardLayout from '@/layout/DashboardLayout'
import AuthLayout from '@/layout/AuthLayout'

Vue.use(Router)

const router = new Router({
    linkExactActiveClass: 'active',
    mode: 'history',
    routes: [
        {
            path: '/',
            redirect: 'users',
            component: DashboardLayout,
            meta: {
                requiresAuth: true,
                guest: false
            },
            children: [
                {
                    path: '/dashboard',
                    name: 'dashboard',
                    meta: {
                        title: 'Tableau de bord', allowed: ['ADMIN', 'MANAGER', 'ACCOUNTANT']
                    },
                    component: () => import(/* webpackChunkName: "demo" */ './views/Dashboard')
                },
                {
                    path: '/transactions',
                    name: 'transactions',
                    meta: {
                        title: 'Transactions',
                        allowed: ['ADMIN', 'MANAGER', 'ACCOUNTANT']
                    },
                    component: () => import('./views/Transactions')
                }, {
                    path: '/add-country',
                    name: 'add-country',
                    meta: {
                        title: 'Ajouter un pays',
                        allowed: ['ADMIN', 'MANAGER', 'ACCOUNTANT']
                    },
                    component: () => import('./views/AddCountry')
                }, {
                    path: '/edit-country',
                    name: 'edit-country',
                    meta: {
                        title: 'Modifier un pays',
                        allowed: ['ADMIN', 'MANAGER', 'ACCOUNTANT']
                    },
                    component: () => import('./views/AddCountry')
                },
                {
                    path: '/users',
                    name: 'users',
                    meta: {
                        title: 'Liste des clients',
                        minLevel: 0
                    },
                    component: () => import('./views/Users')
                }, {
                    path: '/users/user',
                    name: 'user',
                    meta: {
                        title: `Details`,
                        minLevel: 0
                    },
                    component: () => import('./views/User')
                },
                {
                    path: '/providers',
                    name: 'providers',
                    meta: {
                        title: 'Liste des fournisseurs'
                    },
                    component: () => import('./views/Providers')
                }, {
                    path: '/providers/provider',
                    name: 'provider',
                    meta: {
                        title: `Details`
                    },
                    component: () => import('./views/Provider')
                },
                {
                    path: '/providers/provider/lock',
                    name: 'provider-lock',
                    meta: {
                        title: `Verouiller un fournisseur`, allowed: ['ADMIN', 'MANAGER', 'ACCOUNTANT']
                    },
                    component: () => import('./views/ProviderLock')
                }, {
                    path: '/employees/employee/lock',
                    name: 'employee-lock',
                    meta: {
                        title: `Verouiller un employé`, allowed: ['ADMIN', 'MANAGER']
                    },
                    component: () => import('./views/EmployeeLock')
                },
                {
                    path: '/providers/supply',
                    name: 'supply',
                    meta: {
                        title: `approvisionnement`,
                        allowed: ['ADMIN', 'MANAGER', 'ACCOUNTANT', 'WHOLESALER']
                    },
                    component: () => import('./views/Supply')
                },
                {
                    path: '/providers/discharge',
                    name: 'discharge',
                    meta: {
                        title: `Décharge`,
                        allowed: ['ADMIN', 'MANAGER', 'ACCOUNTANT', 'WHOLESALER']
                    },
                    component: () => import('./views/Discharge')
                }, {
                    path: '/employees',
                    name: 'employees',
                    meta: {
                        title: 'Liste des employés'
                    },
                    component: () => import('./views/Employees')
                }, {
                    path: '/employees/employee',
                    name: 'employee',
                    meta: {
                        title: 'Details', allowed: ['ADMIN', 'MANAGER', 'ACCOUNTANT']
                    },
                    component: () => import('./views/Employee')
                }, {
                    path: '/tarification',
                    name: 'tarification',
                    meta: {
                        title: 'tarification', allowed: ['ADMIN', 'MANAGER', 'ACCOUNTANT']
                    },
                    component: () => import('./views/Tarification')
                }, {
                    path: '/add-billing',
                    name: 'add-billing',
                    meta: {
                        title: 'ajouter plan tarifaire', allowed: ['ADMIN', 'MANAGER', 'ACCOUNTANT']
                    },
                    component: () => import('./views/AddBilling')
                }, {
                    path: '/edit-billing',
                    name: 'edit-billing',
                    meta: {
                        title: 'midifer plan tarifaire', allowed: ['ADMIN', 'MANAGER', 'ACCOUNTANT']
                    },
                    component: () => import('./views/AddBilling')
                }, {
                    path: '/add-sponsoring',
                    name: 'add-sponsoring',
                    meta: {
                        title: 'Ajouter un parrainage'
                    },
                    component: () => import('./views/AddSponsoring')
                }, {
                    path: '/sponsorship',
                    name: 'sponsorship',
                    meta: {
                        title: 'Parrainage'
                    },
                    component: () => import('./views/Sponsorship')
                }, {
                    path: '/add-transaction-fee',
                    name: 'add-transaction-fee',
                    meta: {
                        title: 'ajouter plan de facturation', allowed: ['ADMIN', 'MANAGER', 'ACCOUNTANT']
                    },
                    component: () => import('./views/AddTransactionFee')
                }, {
                    path: '/edit-transaction-fee',
                    name: 'edit-transaction-fee',
                    meta: {
                        title: 'modifier plan de facturation', allowed: ['ADMIN', 'MANAGER', 'ACCOUNTANT']
                    },
                    component: () => import('./views/AddTransactionFee')
                },
                {
                    path: '/add-employee',
                    name: 'addEmployee',
                    meta: {
                        title: 'ajouter un employé', allowed: ['ADMIN', 'MANAGER', 'ACCOUNTANT']
                    },
                    component: () => import('./views/AddEmployee')
                }, {
                    path: '/add-provider',
                    name: 'addProvider',
                    meta: {
                        title: 'ajouter un fournisseur', allowed: ['ADMIN', 'MANAGER', 'ACCOUNTANT']
                    },
                    component: () => import('./views/AddProvider')
                },
                {
                    path: '/profile',
                    name: 'profile',
                    meta: {
                        title: 'mon profil'
                    },
                    component: () => import(/* webpackChunkName: "demo" */ './views/UserProfile.vue')
                }
            ]
        },
        {
            path: '/',
            redirect: 'login',
            component: AuthLayout,
            meta: {
                requiresAuth: false,
                guest: true
            },
            children: [
                {
                    path: '/login',
                    name: 'login',
                    component: () => import(/* webpackChunkName: "demo" */ './views/Login.vue')
                }
            ]
        }
    ]
})

const user = JSON.parse(localStorage.getItem('USER'))

router.beforeEach(((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {

        if (localStorage.getItem('TOKEN') == null) {
            next({
                path: '/login',
                params: {nextUrl: to.fullPath}
            })
        } else {
            if (to.meta.allowed !== undefined) {
                if (to.meta.allowed.includes(user.role)) {
                    next()
                } else {
                    next({
                        path: from.fullPath,
                    })
                }
            } else {
                next()
            }
        }

    } else {
        next()
    }
}))

export default router
