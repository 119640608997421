<template>
  <div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }">
    <side-bar
        :background-color="sidebarBackground"
        short-title="POSSA"
        title="POSSA"
    >
      <template slot="links">
        <sidebar-item v-if="dashboard.includes(employee.role)"
                      :link="{
            name: 'Tableau de bord',
            icon: 'ni ni-tv-2 text-primary',
            path: '/dashboard'
          }"
        />

        <sidebar-item v-if="transaction.includes(employee.role)"
                      :link="{name: 'Transactions', icon: 'ni ni-chart-pie-35 text-blue', path: '/transactions'}"/>
        <sidebar-item v-if="tarification.includes(employee.role)"
                      :link="{name: 'Tarification', icon: 'ni ni-money-coins text-red', path: '/tarification'}"/>
        <sidebar-item v-if="users.includes(employee.role)"
                      :link="{name: 'Utilisateurs', icon: 'ni ni-single-02 text-orange', path: '/users'}"/>
        <sidebar-item v-if="providers.includes(employee.role)"
                      :link="{name: 'Fournisseurs', icon: 'ni ni-app text-green', path: '/providers'}"/>
        <sidebar-item v-if="employees.includes(employee.role)"
                      :link="{name: 'Employés', icon: 'ni ni-badge text-info', path: '/employees'}"/>

      </template>
    </side-bar>
    <div class="main-content" :data="sidebarBackground">
      <dashboard-navbar></dashboard-navbar>

      <div @click="toggleSidebar">
        <fade-transition :duration="200" origin="center top" mode="out-in">
          <!-- your content here -->
          <router-view></router-view>
        </fade-transition>
        <content-footer v-if="!$route.meta.hideFooter"></content-footer>
      </div>
    </div>
  </div>
</template>
<script>
import DashboardNavbar from './DashboardNavbar.vue';
import ContentFooter from './ContentFooter.vue';
import {FadeTransition} from 'vue2-transitions';
import {Utils} from "@/utils/utils";

export default {
  components: {
    DashboardNavbar,
    ContentFooter,
    FadeTransition
  },
  data() {
    return {
      dashboard: ['ADMIN', 'ACCOUNTANT', 'MANAGER'],
      transaction: ['ADMIN', 'ACCOUNTANT', 'MANAGER'],
      tarification: ['ADMIN', 'ACCOUNTANT', 'MANAGER'],
      users: ['AGENT', 'WHOLESALER', 'ADMIN', 'ACCOUNTANT', 'MANAGER'],
      providers: ['WHOLESALER', 'ADMIN', 'ACCOUNTANT', 'MANAGER'],
      employees: ['AGENT', 'WHOLESALER', 'ADMIN', 'ACCOUNTANT', 'MANAGER'],
      interval: null,
      employee: {
        account: '',
        role: null
      },
      api: '',
      sidebarBackground: 'red' //vue|blue|orange|green|red|primary
    };
  },
  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    }
  },
  mounted() {
    const data = JSON.parse(localStorage.getItem('USER'))
    this.employee.account = data.account.accountNumber
    this.employee.role = data.role
    this.api = Utils.apiUrl()
    this.interval = setInterval(() => {
      fetch(`${this.api}/refresh-token`, {
        method: 'POST',
        headers: {
          'content-type': 'application/json',
          'connection': 'keep-alive',
          'refresh-token': Utils.jwtTokenKey()
        },
        body: JSON.stringify(this.employee)
      }).then(res => res.json()).then(res => {
        if (res.token !== undefined) {
          Utils.storeToken(res.token)
        }
      }).catch(err => console.error(err))
    }, 1000 * 120)
  },
  beforeDestroy() {
    clearInterval(this.interval)
  }
};
</script>
<style lang="scss">
</style>
