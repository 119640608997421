<template>
  <div class="main-content  bg-default d-flex flex-column justify-content-center bordered">
    <!-- Navbar -->
    <base-nav class="navbar-top navbar-horizontal navbar-dark"
              containerClasses="px-4 container"
              expand>
      <router-link slot="brand" class="navbar-brand _border" to="/">
        <img style="height: 64px;" :src="require('./../assets/logo.png')"/>
      </router-link>

      <template v-slot="{closeMenu}">
        <!-- Collapse header -->
        <div class="navbar-collapse-header d-md-none">
          <div class="row">
            <div class="col-6 collapse-brand">
              <router-link to="/">
                <img alt="POSSA" :src="require('./../assets/logo.png')">
              </router-link>
            </div>
            <div class="col-6 collapse-close">
              <button type="button"
                      @click="closeMenu"
                      class="navbar-toggler"
                      aria-label="Toggle sidenav">
                <span></span>
                <span></span>
              </button>
            </div>
          </div>
        </div>

      </template>
    </base-nav>
    <!-- Header -->

    <!-- Page content -->
    <div class="container pt-8 pb-1">
      <slide-y-up-transition mode="out-in" origin="center top">
        <router-view></router-view>
      </slide-y-up-transition>
    </div>
    <footer class="py-5">
      <div class="container">
        <div class="row align-items-center justify-content-xl-between">
          <div class="col-xl-6">
            <div class="copyright text-center text-xl-left text-muted">
              &copy; {{ year }} <a href="https://www.possa.biz" class="font-weight-bold ml-1"
                                   target="_blank">POSSA</a>
            </div>
          </div>
          <div class="col-xl-6">
            <ul class="nav nav-footer justify-content-center justify-content-xl-end">
              <li class="nav-item">
                <a href="https://www.possa.biz" class="nav-link text-orange" target="_blank">Fini les problèmes de
                  petite
                  monnaie</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>
<script>
import {SlideYUpTransition} from 'vue2-transitions'

export default {
  name: 'auth-layout',
  components: {
    SlideYUpTransition
  },
  data: () => ({
    year: new Date().getFullYear(),
    showMenu: false
  })
}
</script>
<style scoped>
.bordered {
  min-height: 100vh;
  max-width: 100vw;
  overflow-x: hidden;
}

._border {
  position: absolute;
  top: 2rem;
  left: 0;
  right: 0;
  width: 100vw;
  display: flex;
  align-items: center;
  height: 80px;
  justify-content: center;
}

</style>
